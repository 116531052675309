import { initializeApp, getApps } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC43UVEvhP7_YifNb37XzNde19v-6pBNDs",
  authDomain: "merthiolate-537c1.firebaseapp.com",
  projectId: "merthiolate-537c1",
  storageBucket: "merthiolate-537c1.appspot.com",
  messagingSenderId: "1020805118239",
  appId: "1:1020805118239:web:fb59eb9c5d45fdfb67d82a",
  measurementId: "G-KBHCR052WV"
};

let app;

if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const db = getFirestore(app);

export { db };