import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CadastroItemPage: React.FC = () => {
  const [hasInserted, setHasInserted] = useState(false);

  const insertData = async () => {
    const itemData = {
      categoria: 'moda-feminina',
      imagem: ['1.jpg', '2.jpg', '3.jpg'],
      link: 'bermuda-feminina-suplex-costura-trançada-cinza-claro-bs02t',
      nome: 'Bermuda Feminina Suplex Costura Trançada Cinza Claro BS02T',
      subcategoria: 'tops',
      tamanho: ['PP', 'P', 'M', 'G', 'GG'],
      valor: 109.90,
      cores: [
        {
          codigoCor: '#B7B2A2',
          legendaCor: 'cinza-claro',
          nomeCor: 'Cinza Claro'
        }
      ]
    };

    try {
      const docRef = await addDoc(collection(db, 'item'), itemData);
      toast.success('Dados inseridos no Firestore com sucesso!');
      
      setHasInserted(true); 
    } catch (error) {
      toast.error('Erro ao inserir dados no Firestore.');
    }
  };

  return (
    <div>
      <h1>Página Especial</h1>
      <p>Clique no botão abaixo para inserir dados no Firestore.</p>
      <button onClick={insertData} disabled={hasInserted}>
        {hasInserted ? 'Dados já inseridos' : 'Inserir Dados'}
      </button>
      <ToastContainer />
    </div>
  );
};

export default CadastroItemPage;