import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

const FiltroPage: React.FC<{ handleShowCart: () => void; updateCart: () => void }> = ({ handleShowCart, updateCart }) => {
  const [filteredItems, setFilteredItems] = useState<{ id: string; link: string; nome: string; valor: number; imagem: string[]; tamanho: string[] }[]>([]);
  
  const location = useLocation();
  
  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  // Adicionar o parâmetro "pesquisa"
  const getQueryParams = (search: string) => {
    const params = new URLSearchParams(search);
    return {
        categoria: params.get('categoria'),
        subcategoria: params.get('subcategoria'),
        pesquisa: params.get('pesquisa')  // Adicionando pesquisa
    };
  };

  useEffect(() => {
    const { categoria, subcategoria, pesquisa } = getQueryParams(location.search);

    const fetchFilteredItems = async () => {
      let q;
      
      // Se "pesquisa" estiver presente, ignora "categoria" e "subcategoria"
      if (pesquisa) {
        q = query(
          collection(db, 'item'),
          where('nome', '>=', pesquisa),    // Filtra os nomes que começam com o termo de pesquisa
          where('nome', '<=', pesquisa + '\uf8ff')  // Range para filtrar todos os nomes que começam com o termo
        );
      } else if (categoria && subcategoria) {
        q = query(
          collection(db, 'item'),
          where('categoria', '==', categoria),
          where('subcategoria', '==', subcategoria)
        );
      }

      if (q) {
        const querySnapshot = await getDocs(q);
        const items = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            nome: data.nome || 'Nome não disponível',
            link: data.link || '',
            valor: data.valor || 0,
            imagem: data.imagem || [],  
            tamanho: data.tamanho || []  
          };
        });
        setFilteredItems(items);
      }
    };

    fetchFilteredItems();
  }, [location.search]);

  return (
    <Container className='mt-5'>
      <Row>
        {filteredItems.length > 0 ? (
          filteredItems.map(item => (
            <Col lg={3} className='mb-3' key={item.id}>
              <Card className='border-grey'>
                <Card.Body className='p-1'>
                  <AwesomeSlider style={{ width: "100%", height: "400px" }} className='mb-5'>
                    {item.imagem.map((img, index) => {
                      const imagePath = `/assets/${item.link}/${img}`;

                      const handleImageClick = (event: any) => {
                        if (event.target.tagName === 'IMG') {
                          window.location.href = item.link;
                        }
                      };

                      return (
                        <div key={index} data-src={imagePath} onClick={handleImageClick} style={{ cursor: "pointer" }} />
                      );
                    })}
                  </AwesomeSlider>

                  <p className='mb-1'>{item.nome || 'Nome não disponível'}</p>
                  <h5 className='mb-2'>R$ {formatCurrencyBRL(item.valor)}</h5>
                </Card.Body>
              </Card>
            </Col>
          ))
        ) : (
          <p className='text-center'>Nenhum item encontrado.</p>
        )}
      </Row>
    </Container>
  );
};

export default FiltroPage;