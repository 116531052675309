import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { FaPix, FaRegCreditCard } from 'react-icons/fa6';
import { ToastContainer, toast } from 'react-toastify';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';
import 'react-awesome-slider/dist/styles.css';

interface Frete {
  id: number;
  name: string;
  price: number;
  delivery_time: number;
  currency: string;
  company: {
    name: string;
    picture: string;
  };
}

interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  size: string;
  image: string;
  color: string;
}

interface AlertProps {
  id: number;
  type: 'info' | 'success' | 'warning' | 'danger';
  message: string;
}

const CheckoutPage: React.FC<{ handleShowCart: () => void; updateCartDetails: () => void }> = ({ handleShowCart, updateCartDetails }) => {
  
  const [mensagem, setMensagem] = useState<string | null>(null);
  const [alerts, setAlerts] = useState<AlertProps[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalPedido, setTotalPedido] = useState<number>(totalPrice);
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [freteSelecionado, setFreteSelecionado] = useState<Frete | null>(null);
  const [cep, setCep] = useState('');
  const [pedido, setPedido] = useState<any>(null);

  const [endereco, setEndereco] = useState({
    cep: '',
    logradouro: '',
    bairro: '',
    cidade: '',
    estado: '',
    numero: '',
    complemento: '',
    destinatario: ''
  });

  const [dadosPessoais, setDadosPessoais] = useState({
    nome: '',
    sobrenome: '',
    email: '',
    cpf: '',
    telefone: ''
  });

  const calcularParcelamento = (totalPedido:any, parcelas:any) => {

    const valorBase = totalPedido / (1 + 0.0299);
    let valorComTaxa = totalPedido;

    if (parcelas === 1) {
     
      return totalPedido;
    } else if (parcelas >= 2 && parcelas <= 6) {
     
      const diferencaTaxa = (0.0349 - 0.0299) + 0.0299;
      valorComTaxa = valorBase * (1 + diferencaTaxa) + 0.49;
    } else if (parcelas >= 7 && parcelas <= 12) {
     
      const diferencaTaxa = (0.0399 - 0.0299) + 0.0299;
      valorComTaxa = valorBase * (1 + diferencaTaxa) + 0.49;
    }

    return valorComTaxa / parcelas;
  };

  const [erro, setErro] = useState('');
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    const db = getFirestore();
  
    const fetchPedido = async () => {
      
      const storedEmail = localStorage.getItem('merthiolateUserEmail');

      if (storedEmail) {
        const pedidosRef = collection(db, 'pedido');
        const q = query(pedidosRef, where("dadosPessoais.email", "==", storedEmail));
        
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const pedidoData = querySnapshot.docs[0].data();
  
          const totalQuantity = pedidoData.itens.reduce((acc: any, item: any) => {
            return acc + item.quantity;
          }, 0);
    
          setTotalQuantity(totalQuantity);
          setPedido(pedidoData);
  
          setDadosPessoais({
            nome: pedidoData.dadosPessoais.nome,
            sobrenome: pedidoData.dadosPessoais.sobrenome,
            email: pedidoData.dadosPessoais.email,
            cpf: pedidoData.dadosPessoais.cpf,
            telefone: pedidoData.dadosPessoais.telefone,
          });
  
          setEndereco({
            cep: pedidoData.enderecoEntrega.cep,
            logradouro: pedidoData.enderecoEntrega.logradouro,
            bairro: pedidoData.enderecoEntrega.bairro,
            cidade: pedidoData.enderecoEntrega.cidade,
            estado: pedidoData.enderecoEntrega.estado,
            numero: pedidoData.enderecoEntrega.numero,
            complemento: pedidoData.enderecoEntrega.complemento,
            destinatario: pedidoData.enderecoEntrega.destinatario
          });
  
          setCartItems(pedidoData.itens || []);
          setTotalPedido(pedidoData.valorTotalCompra);
          setFreteSelecionado(pedidoData.freteSelecionado);
          setMensagem(null);
        } else {
        
          setMensagem('Pedido vazio. Nenhum pedido encontrado.');
        }
      } else {
        
        setMensagem('Pedido vazio. Nenhum pedido encontrado.');
      }
    };
  
    fetchPedido();
  }, []);  

  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <>
    {mensagem ? (

        <>
        <div className='text-center'>
          <Link to="/">
            <img src="/assets/logo-merthiolate-1.jpg" alt="Merthiolate" style={{width:'60px'}} />
          </Link>
        </div>
        <h4 className='text-center pb-4 pt-2'>{mensagem}</h4>
        <div>
          <Link to="/">
            <Button variant='danger' className='br-1 px-5 py-2'>VOLTAR PARA A LOJA</Button>
          </Link>
        </div>
        </>

      ) : (
      <>
      <ToastContainer position='top-center' pauseOnHover hideProgressBar={true} closeOnClick />
      <Container className='mt-3'>
        <Row>
          <div className='text-center'>
            <Link to="/">
              <img src="/assets/logo-merthiolate-1.jpg" alt="Merthiolate" style={{width:'60px'}} />
            </Link>
          </div>
          <h4 className='text-center pb-4'>Finalizar compra</h4>
        </Row>
        <Row className='pb-3'>
          <Col lg={4}>
            <h5>Itens <b>({totalQuantity})</b></h5>
            <hr className='mb-4' />
            {cartItems.length > 0 ? (
              cartItems.map((item) => (
                <Row className='mb-3' key={item.id}>
                  <Col lg={3} className='trinta-per-mob'>
                    <img src={item.image} alt={item.name} style={{ width: '100%' }} />
                  </Col>
                  <Col lg={9} className='text-start setenta-per-mob'>
                    <h6 className="fw-300 mb-1">
                      <span style={{ width: '80%', float: 'left' }}>
                        <b>{item.name}</b>
                      </span>
                    </h6>
                    <h6 className='fw-300 mb-1'>Tamanho: <b>{item.size}</b></h6>

                    <h6 className="fw-300 mb-1 mt-2 float-start cemPor"><span style={{float:'left'}}>Cor:</span> <span className={`circuloCor2 float-start ms-2 d-block`} style={{ backgroundColor: item.color }}></span></h6>

                    <h6 className='fw-300 mb-2'>Quantidade: <b>{item.quantity}</b></h6> 
                    <h6><b>{formatCurrencyBRL(item.price * item.quantity)}</b></h6>
                  </Col>
                </Row>
              ))
            ) : (
              <p>Carrinho vazio</p>
            )}

            {freteSelecionado && (
            <Row>

              <hr />

              <Col lg={12} className='text-start'>
                <Form.Label className='fw-300 text-start mb-0'>
                Valor total dos itens: <b> 
                  {cartItems.length > 0 
                    ? formatCurrencyBRL(cartItems.reduce((total, item) => total + (item.price * item.quantity), 0)) 
                    : '--'}
                  </b>
                </Form.Label>
              </Col>

                <Col lg={12} className='text-start'>
                    <Form.Label className='fw-300 mb-0'>Preço do frete: <b>{formatCurrencyBRL(freteSelecionado.price)}</b></Form.Label>
                </Col>
                <Col lg={12} className='text-start'>
                  <Form.Label className='fw-300 mb-0'>Valor com frete: <b>{formatCurrencyBRL(totalPedido)}</b></Form.Label>
                </Col>
              </Row>
              )}

          </Col>

          <Col lg={4} className='text-start'>
            <h5 className='text-center'>Dados pessoais e endereço de entrega</h5>
            <hr className='mb-4' />
            <Row>
              <Col lg={6}>
                <Form.Label htmlFor="nome" className='fw-300'>Nome: <b>{dadosPessoais.nome}</b></Form.Label>
              </Col>
              <Col lg={6}>
                <Form.Label htmlFor="sobrenome" className='fw-300'>Sobrenome: <b>{dadosPessoais.sobrenome}</b></Form.Label>
              </Col>
              <Col lg={12}>
                <Form.Label htmlFor="email" className='fw-300'>E-mail: <b>{dadosPessoais.email}</b></Form.Label>
              </Col>
              <Col lg={6}>
                <Form.Label htmlFor="cpf" className='fw-300'>CPF: <b>{dadosPessoais.cpf}</b></Form.Label>
              </Col>
              <Col lg={6}>
                <Form.Label htmlFor="telefone" className='fw-300'>Telefone: <b>{dadosPessoais.telefone}</b></Form.Label>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                  <Form.Label className='fw-300'>Endereço: <b>{endereco.logradouro}</b></Form.Label>
              </Col>
              <Col lg={6}>
                  <Form.Label className='fw-300'>Bairro: <b>{endereco.bairro}</b></Form.Label>
              </Col>
              <Col lg={6}>
                  <Form.Label className='fw-300'>Cidade: <b>{endereco.cidade}</b></Form.Label>
              </Col>
              <Col lg={6}>
                  <Form.Label className='fw-300'>Estado: <b>{endereco.estado}</b></Form.Label>
              </Col>  
              <Col lg={6}>
                  <Form.Label className='fw-300'>Número: <b>{endereco.numero}</b></Form.Label>
              </Col>  
              <Col lg={12}>
                  <Form.Label className='fw-300'>Complemento: <b>{endereco.complemento ? endereco.complemento : '--'}</b></Form.Label>
              </Col>
              <Col lg={12}>
                  <Form.Label className='fw-300'>Destinatário: <b>{endereco.destinatario}</b></Form.Label>
              </Col>
              <h5 className='text-center pb-1 pt-2'>Pagamento</h5>
              <hr className='mb-4' />
              {freteSelecionado && (
                <Row>
                <Col lg={6}>
                    <Form.Label className='fw-300'>Transportadora: <b>{freteSelecionado.name}</b></Form.Label>
                </Col>
                  <Col lg={6}>
                      <Form.Label className='fw-300'>Preço do frete: <b>{formatCurrencyBRL(freteSelecionado.price)}</b></Form.Label>
                  </Col>
                  <Col lg={6}>
                      <Form.Label className='fw-300'>Prazo de entrega: <b>{freteSelecionado.delivery_time}</b></Form.Label>
                  </Col>
                  <Col lg={6}>
                    <Form.Label className='fw-300'>Valor total: <b>{formatCurrencyBRL(totalPedido)}</b></Form.Label>
                  </Col>
                </Row>
              )}
            </Row>
          </Col>

          <Col lg={4}>
            
        <h5 className='text-center pb-2'>Pague com Pix ou Cartão de Crédito</h5>
        
        <Accordion defaultActiveKey={['0']}>
        <Accordion.Item eventKey="0">
        <Accordion.Header><FaPix /> &nbsp; PIX &nbsp; <small>(clique para expandir)</small></Accordion.Header>
        <Accordion.Body>
        <img src="/assets/logo-pix.png"  style={{width:'150px'}} className='mt-3' />
        <div className='py-3 my-3'>
          <h5 className='pb-3'>Valor total: <b>{formatCurrencyBRL(totalPedido)}</b></h5>
          <Button variant="primary" size="lg" className='px-1 br-1 cemPor'>GERAR PIX</Button>
        </div>
        </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
        <Accordion.Header><FaRegCreditCard /> &nbsp; Cartão de Crédito &nbsp; <small>(clique para expandir)</small></Accordion.Header>
        <Accordion.Body>
        
        <Col lg={12} className='text-start'>
        <Form.Label htmlFor="inputPassword5">Número do cartão</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        
        <Col lg={12} className='text-start'>
        <Form.Label htmlFor="inputPassword5">Nome impresso no cartão</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        
        <Col lg={12} className='text-start'>
        <Form.Label htmlFor="inputPassword5">Validade do cartão</Form.Label>
        
        <Row>
        <Col lg={12}>
          <Form.Select aria-label="Mês" size="lg">
            <option>Mês</option>
            <option value="1">1 - Janeiro</option>
            <option value="2">2 - Fevereiro</option>
            <option value="3">3 - Março</option>
            <option value="4">4 - Abril</option>
            <option value="5">5 - Maio</option>
            <option value="6">6 - Junho</option>
            <option value="7">7 - Julho</option>
            <option value="8">8 - Agosto</option>
            <option value="9">9 - Setembro</option>
            <option value="10">10 - Outubro</option>
            <option value="11">11 - Novembro</option>
            <option value="12">12 - Dezembro</option>
          </Form.Select>
        </Col>
        <Col lg={12} className='pt-3'>
        <Form.Select aria-label="Ano" size='lg'>
        <option>Ano</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
        </Form.Select>
        </Col>
        </Row>
        </Col>
        <Col lg={12} className='text-start mt-3'>
        
        <Row>
        <Col lg={12}>
        <Form.Label htmlFor="inputPassword5">Código Segurança</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        <Col lg={12}>
        <Form.Label htmlFor="inputPassword5">CPF do titular</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        </Row>
        </Col>
        <Col lg={12} className='text-start'>
        <Form.Label htmlFor="inputPassword5">Parcela(s)</Form.Label>
        <Form.Select aria-label="Parcelamento" size='lg'>
          <option value={totalPedido}>
            1x de {formatCurrencyBRL(totalPedido)} (à vista)
          </option>
          {Array.from({ length: 11 }, (_, i) => i + 2).map(parcelas => {
            const valorParcela = calcularParcelamento(totalPedido, parcelas);
            return (
              <option key={parcelas} value={valorParcela * parcelas}>
                {parcelas}x de {formatCurrencyBRL(valorParcela)}
              </option>
            );
          })}
        </Form.Select>
        </Col>
        
        <div className='py-3 my-3'>
        <Button variant="primary" size="lg" className='px-1 br-1 cemPor'>FINALIZAR COMPRA</Button>
        </div>
        
        </Accordion.Body>
        </Accordion.Item>      
        </Accordion>     
            
            </Col>

        </Row>
      </Container>
      </>
      )}
    </>
  );
};

export default CheckoutPage;