import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import HeaderComponent from '../components/HeaderComponent';
import 'react-awesome-slider/dist/styles.css';
import logoPix from '../assets/logo-pix.png';
import { InputGroup, Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FaPix } from "react-icons/fa6";
import { FaRegCreditCard } from "react-icons/fa6";

const MeuspedidosPage: React.FC<{ handleShowCart: () => void; updateCart: () => void }> = ({ handleShowCart, updateCart }) => {

    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const products = Array.from({ length: 2 }, (_, index) => index);
    
    const [selectedImage, setSelectedImage] = useState('../public/assets/1.jpg');
    const [quantity, setQuantity] = useState(1); 
    
    const increment = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };
    
    const decrement = () => {
        setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));
    };
    
    const [tamanhoSelecionado, setTamanhoSelecionado] = useState(null);
    
    const tamanhos = ['PP', 'P', 'M', 'G', 'GG'];
    
    const handleTamanhoClick = (tamanho:any) => {
        
        setTamanhoSelecionado(tamanho);
    };
    
    const orders = [
        { date: '10/09/2022', orderId: 123, address: 'Rua Afonso de Castro, n. 1243, Bloco A, São Paulo / SP', recipient: 'João da Silva', paymentMethod: 'Cartão de crédito 3x', itemsTotal: 499.00, shippingCost: 9.90, orderTotal: 508.90 },
        { date: '11/09/2022', orderId: 124, address: 'Rua Alberto Santos, n. 321, Bloco B, Rio de Janeiro / RJ', recipient: 'Maria Oliveira', paymentMethod: 'Boleto', itemsTotal: 299.00, shippingCost: 12.90, orderTotal: 311.90 },
        { date: '12/09/2022', orderId: 125, address: 'Av. Paulista, n. 1000, Bloco C, São Paulo / SP', recipient: 'Cláudio Ramos', paymentMethod: 'PIX', itemsTotal: 150.00, shippingCost: 0.00, orderTotal: 150.00 },
        { date: '13/09/2022', orderId: 126, address: 'Rua das Flores, n. 450, Bloco D, Belo Horizonte / MG', recipient: 'Ana Costa', paymentMethod: 'Cartão de débito', itemsTotal: 750.00, shippingCost: 15.00, orderTotal: 765.00 },
        { date: '14/09/2022', orderId: 127, address: 'Av. Brasil, n. 2340, Bloco E, Brasília / DF', recipient: 'Pedro Alvares', paymentMethod: 'Cartão de crédito 6x', itemsTotal: 1200.00, shippingCost: 20.00, orderTotal: 1220.00 }
    ];
    
    return (
        <>
      <Container className='mt-5'>
        <Row>
        <>
        <Row>
        <h4 className='text-center pb-4'>Meus pedidos</h4>
        </Row>
        
        <Row className='pb-3'>
        <Col lg={12}>
        <Accordion defaultActiveKey={['0']}>
        {orders.map((order, index) => (
            <Accordion.Item eventKey={String(index)}>
            <Accordion.Header>{order.date}</Accordion.Header>
            <Accordion.Body>
            <Row className='mb-3'>
            <Col lg={6} className='text-start'>
            <h6>Número do pedido: <b>{order.orderId}</b></h6>
            <h6>Data do pedido: <b>{order.date}</b></h6>
            <h6>Endereço de entrega:</h6>
            <h6><b>{order.address}</b></h6>
            <h6>Nome do destinatário: <b>{order.recipient}</b></h6>
            <h6>Forma de pagamento: <b>{order.paymentMethod}</b></h6>
            <h6>Valor total dos itens: <b>R$ {order.itemsTotal.toFixed(2)}</b></h6>
            <h6>Valor do frete: <b>R$ {order.shippingCost.toFixed(2)}</b></h6>
            <h6>Valor total do pedido: <b>R$ {order.orderTotal.toFixed(2)}</b></h6>
            </Col>
            <Col lg={6}>
            <Row className='mb-3'>
            <Col lg={2}>
            <img src='../public/assets/2.jpg' alt="Thumbnail 2" style={{ width: '100%' }} />
            </Col>
            <Col lg={10} className='text-start'>
            <h6 className='fw-300 mb-1'>Nome do produto</h6>
            <h6 className='fw-300 mb-1'>Tamanho: <b>GG</b></h6>
            <h6 className='fw-300 mb-1'>Quantidade: <b>9</b></h6>
            <h6><b>R$ 199,90</b></h6>
            </Col>
            </Row>                  
            <Row className='mb-3'>
            <Col lg={2}>
            <img src='../public/assets/2.jpg' alt="Thumbnail 2" style={{ width: '100%' }} />
            </Col>
            <Col lg={10} className='text-start'>
            <h6 className='fw-300 mb-1'>Nome do produto</h6>
            <h6 className='fw-300 mb-1'>Tamanho: <b>GG</b></h6>
            <h6 className='fw-300 mb-1'>Quantidade: <b>9</b></h6>
            <h6><b>R$ 199,90</b></h6>
            </Col>
            </Row>                  
            </Col>
            </Row>            
            </Accordion.Body>
            </Accordion.Item> 
        ))}
        </Accordion>
        </Col>
        </Row>    
        
        </>
        </Row>
        </Container>
        </>
    );
};

export default MeuspedidosPage;