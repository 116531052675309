import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import HeaderComponent from '../components/HeaderComponent';
import 'react-awesome-slider/dist/styles.css';
import logoPix from '../assets/logo-pix.png';
import { InputGroup, Form } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { FaPix } from "react-icons/fa6";
import { FaRegCreditCard } from "react-icons/fa6";

const MeuCadastroPage: React.FC<{ handleShowCart: () => void; updateCart: () => void }> = ({ handleShowCart, updateCart }) => {

    const [show, setShow] = useState(false);
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const products = Array.from({ length: 2 }, (_, index) => index);
    
    const [selectedImage, setSelectedImage] = useState('../public/assets/1.jpg');
    const [quantity, setQuantity] = useState(1); 
    
    const increment = () => {
        setQuantity(prevQuantity => prevQuantity + 1);
    };
    
    const decrement = () => {
        setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));
    };
    
    const [tamanhoSelecionado, setTamanhoSelecionado] = useState(null);
    
    const tamanhos = ['PP', 'P', 'M', 'G', 'GG'];
    
    const handleTamanhoClick = (tamanho:any) => {
        
        setTamanhoSelecionado(tamanho);
    };
    
    return (
        <>
        <Container className='mt-5'>
        <Row>
        <>
        
        <Row>
        <h4 className='text-center pb-4'>Meu cadastro</h4>
        </Row>
        
        <Row className='pb-3'>   
        
        <Col lg={5} className='text-start'>
        
        <h5 >Dados pessoais</h5>
        <hr className='mb-4' />
        
        <Row>
        
        <Col lg={6}>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Nome</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        <Col lg={6}>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Sobrenome</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        <Col lg={12}>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>E-mail</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        <Col lg={6}>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>CPF</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        <Col lg={6}>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Telefone</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        
        <div className='pb-3 mt-3 text-center'>
        <Button variant="primary" size="lg" className='px-5 br-1'>SALVAR DADOS</Button>
        </div>
        </Row>
        
        </Col>
        
        <Col lg={1}></Col>

        <Col lg={6}>
        
        <h5>Endereço</h5>
        <hr className='mb-3' />
        
        <Row>

        <Col lg={3} className='mb-1 text-start'>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>CEP</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        
        <Col lg={9} className='text-start'>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Endereço</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        
        <Col lg={3} className='text-start'>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Número</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        
        <Col lg={3} className='text-start'>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Complemento</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>
        
        <Col lg={6} className='text-start'>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Bairro</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>

        <Col lg={6} className='text-start'>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Cidade</Form.Label>
        <Form.Control
        type="text"
        id="inputPassword5"
        aria-describedby="passwordHelpBlock"
        className='br-1 mb-3'
        size='lg'
        />
        </Col>    

        
        <Col lg={6} className='text-start'>
        <Form.Label htmlFor="inputPassword5" className='fw-300'>Estado</Form.Label>
        <Form.Select aria-label="Mês" size='lg' className='br-1'>
        <option>Selecione</option>
        <option value="1">One</option>
        <option value="2">Two</option>
        <option value="3">Three</option>
        </Form.Select>
        </Col>
        

        <div className='pb-3 mt-3 text-center'>
        <Button variant="primary" size="lg" className='px-5 br-1'>SALVAR ENDEREÇO</Button>
        </div>

        </Row>

        </Col>
        
        </Row>      
        </>
        </Row>
        </Container>
        </>
    );
};

export default MeuCadastroPage;