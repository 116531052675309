import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, NavDropdown, Form, Button, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom'; 
import { IoBag } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  size: string;
  image: string;
}

interface HeaderComponentProps {
  handleShowCart: () => void;
  totalQuantity: number;
  totalPrice: number;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({ handleShowCart, totalQuantity, totalPrice }) => {
  const [categories, setCategories] = useState<any[]>([]);
  const [subcategories, setSubcategories] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState(''); 
  const navigate = useNavigate();  

  const fetchCategoriesAndSubcategories = async () => {
   
    const categoriesSnapshot = await getDocs(collection(db, 'categoria'));
    const fetchedCategories = categoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const subcategoriesSnapshot = await getDocs(collection(db, 'subcategoria'));
    const fetchedSubcategories = subcategoriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    setCategories(fetchedCategories);
    setSubcategories(fetchedSubcategories);
  };

  useEffect(() => {
    fetchCategoriesAndSubcategories();
  }, []);

  const handleLoadCartValues = () => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
      const parsedCart: CartItem[] = JSON.parse(storedCart);
      const totalQuantity = parsedCart.reduce((acc, item) => acc + item.quantity, 0);
      const totalPrice = parsedCart.reduce((acc, item) => acc + item.price * item.quantity, 0);
    }
  };

  useEffect(() => {
    handleLoadCartValues();
  }, []);

  const handleSearch = () => {
    if (searchQuery.trim() !== '') {
      navigate(`/filtro?pesquisa=${encodeURIComponent(searchQuery)}`);
    }
  };

  return (
    <Navbar expand="lg" className="">
      <Container fluid>
        <Navbar.Brand href="/">
          <img src="/assets/logo-merthiolate-1.jpg" alt="Merthiolate" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0 br-1" navbarScroll>
            {categories.map(category => (
              <NavDropdown key={category.id} title={category.nome} id={`navbarScrollingDropdown-${category.link}`}>
                {subcategories
                  .filter(subcategory => subcategory.categoria === category.link)
                  .map(subcategory => (
                    <NavDropdown.Item 
                      key={subcategory.id} 
                      href={`/filtro?categoria=${category.link}&subcategoria=${subcategory.link}`}>
                      {subcategory.nome}
                    </NavDropdown.Item>
                  ))}
              </NavDropdown>
            ))}
          </Nav>

          <InputGroup className="ms-5 me-5 mb-3 mt-3 border-dark m-0-mob pb-10-mob d-none">
            <Form.Control
              placeholder="Pesquisar produto"
              aria-label="Pesquisar produto"
              aria-describedby="basic-addon2"
              className='br-1'
              value={searchQuery} 
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button 
              variant="dark" 
              id="button-addon2" 
              className="px-5 br-1"
              onClick={handleSearch}
            >
              BUSCAR
            </Button>
          </InputGroup>

          <Button variant="danger" onClick={handleShowCart} style={{ minWidth: "220px" }} className='br-1 cem-por-mob'>
            &nbsp; <IoBag /> {totalQuantity} {totalQuantity === 1 ? 'Item' : 'Itens'} - R$ {totalPrice.toFixed(2)} <IoMdArrowDropdown />
          </Button>

          {totalQuantity > 0 && (
            <Link to="/resumo">
              <Button 
                variant="danger" 
                className="px-5 br-1 cemPor cem-por-mob ml-0-mob mt-10-mob"
                style={{width:'250px',marginLeft:'7px'}}
              >
                FINALIZAR COMPRA
              </Button>
            </Link>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderComponent;