import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Offcanvas, Row, Col } from 'react-bootstrap';
import { FaRegTrashAlt, FaMinus, FaPlus } from 'react-icons/fa';

interface CartItem {
  id: string;
  name: string;
  price: number;
  quantity: number;
  size: string;
  image: string;
  color: string;
}

interface CarrinhoComponentProps {
  showCart: boolean;
  handleCloseCart: () => void;
  updateCartDetails: () => void;
}

const CarrinhoComponent: React.FC<CarrinhoComponentProps> = ({ showCart, handleCloseCart, updateCartDetails }) => {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);

  useEffect(() => {
    if (showCart) {
      const storedCart = localStorage.getItem('cart');
      if (storedCart) {
        setCartItems(JSON.parse(storedCart));
      }
    }
  }, [showCart]);

  useEffect(() => {
    updateCartDetails();
  }, [cartItems, updateCartDetails]);

  const removeFromCart = (id: string) => {
    const updatedCart = cartItems.filter(item => item.id !== id);
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    updateCartDetails();
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cart');
    updateCartDetails();
  };

  const increment = (id: string) => {
    const updatedCart = cartItems.map(item => 
      item.id === id ? { ...item, quantity: item.quantity + 1 } : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    updateCartDetails();
  };

  const decrement = (id: string) => {
    const updatedCart = cartItems.map(item => 
      item.id === id && item.quantity > 1 ? { ...item, quantity: item.quantity - 1 } : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    updateCartDetails();
  };

  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <>
      <Offcanvas show={showCart} onHide={handleCloseCart} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Minha Sacola</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div style={{ width: '100%', float: 'left' }}>
            <button onClick={clearCart} className="btn btn-sm btn-dark br-1 mb-3" style={{ float: 'right' }}>
              Limpar Carrinho
            </button>
            <hr style={{ width: '100%', float: 'left' }} className='mt-1' />
          </div>
          {cartItems.length > 0 ? (
            <>
              {cartItems.map((item, index) => (
                <Row key={index} className="pb-3">
                  <Col lg={3} className='trinta-per-mob'>
                    <img src={item.image} alt={item.name} style={{ width: '100%' }} />
                  </Col>
                  <Col lg={9} className="text-start setenta-per-mob">
                    <h6 className="fw-300 mb-1">
                      <span style={{ width: '80%', float: 'left' }}>
                        <b>{item.name}</b>
                      </span>
                      <span style={{ width: '20%', float: 'left' }}>
                        <FaRegTrashAlt onClick={() => removeFromCart(item.id)} style={{ cursor: 'pointer' }} />
                      </span>
                    </h6>
                    <h6 className="fw-300 mb-1 mt-2 float-start cemPor">Tamanho: <b>{item.size}</b></h6>
                    <h6 className="fw-300 mb-1 mt-2 float-start cemPor"><span style={{float:'left'}}>Cor:</span> <span className={`circuloCor2 float-start ms-2 d-block`} style={{ backgroundColor: item.color }}></span></h6>
                    <h6 className="float-start mt-1 cemPor">Valor unitário: <b>{formatCurrencyBRL(item.price)}</b></h6>
                    <h6 className="float-start cemPor pb-0">Valor total: <b>{formatCurrencyBRL((item.price * item.quantity))}</b></h6>
                    <h6 className="float-start cemPor pb-0">Quantidade: </h6>
                    <div className='d-flex align-items-center br-1 text-start' style={{border:"1px solid #999",width:"120px"}}>
                      <Button variant="none" className='px-2 py-1 br-1' onClick={() => decrement(item.id)}>
                        <FaMinus />
                      </Button>
                      <div className='mx-2 text-center d-flex justify-content-center align-items-center' style={{ width: '30px', height: '30px', fontSize:'1.2em' }}>
                        {item.quantity}
                      </div>
                      <Button variant="none" className='px-2 py-1 br-1' onClick={() => increment(item.id)}>
                        <FaPlus />
                      </Button>
                    </div>
                  </Col>
                  <hr className='mt-4' />
                </Row>
              ))}
              <h5 className='py-2'>
                Valor total: <b>{formatCurrencyBRL(cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0))}</b>
              </h5>
              <Link to="/resumo">
                <Button 
                  variant="danger" 
                  className="px-5 br-1 cemPor"
                  onClick={handleCloseCart} 
                >
                  FINALIZAR COMPRA
                </Button>
              </Link>
            </>
          ) : (
            <p>Carrinho vazio</p>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CarrinhoComponent;