import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
import HeaderComponent from '../components/HeaderComponent';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC43UVEvhP7_YifNb37XzNde19v-6pBNDs",
  authDomain: "merthiolate-537c1.firebaseapp.com",
  projectId: "merthiolate-537c1",
  storageBucket: "merthiolate-537c1.appspot.com",
  messagingSenderId: "1020805118239",
  appId: "1:1020805118239:web:fb59eb9c5d45fdfb67d82a",
  measurementId: "G-KBHCR052WV"
};

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApps()[0];
}

const analytics = getAnalytics(app);
export const db = getFirestore(app);

const HomePage: React.FC<{ handleShowCart: () => void; updateCart: () => void }> = ({ handleShowCart, updateCart }) => {

  const [todosData, setTodosData] = useState<{ id: string; link: string; nome: string; valor: number; imagem: string[]; tamanho: string[] }[]>([]);

  const formatCurrencyBRL = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, 'item'));
      const items = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          nome: data.nome || '',
          link: data.link || '',
          valor: data.valor || 0,
          imagem: data.imagem || [],  
          tamanho: data.tamanho || []  
        };
      });
      setTodosData(items);
    };

    fetchData();
  }, []);

  return (
    <>
      <Container className='mt-5 mt-2-mob'>
        <Row>
          {todosData.map(item => (
            <Col lg={3} className='mb-3' key={item.id}>
                <Card className='border-grey'> 
                    <Card.Body className='p-1'>

                      <AwesomeSlider style={{ width: "100%", height: "400px" }} className='mb-5'>
                        {item.imagem.map((img, index) => {
                          const imagePath = `/assets/${item.link}/${img}`;

                          const handleImageClick = (event:any) => {
                            if (event.target.tagName === 'IMG') {

                              window.location.href = item.link;
                            }
                          };

                          return (
                            <div key={index} data-src={imagePath} onClick={handleImageClick} style={{cursor:"pointer"}} />
                          );
                        })}
                      </AwesomeSlider>
                      <h6 className='mb-1 px-1 d-flex align-items-center justify-content-center' style={{minHeight:"65px"}}>{item.nome}</h6>
                      <h4 className='mb-2'>{formatCurrencyBRL(item.valor)}</h4>
                    </Card.Body>
                </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default HomePage;